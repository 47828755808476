import React from 'react'
import './App.css'

function App() {
  return (
    <div className="sarah">
      <a className="credits" href="https://www.sueforbesphotography.com/" target="_blank">Photo © Sue Forbes</a>
    </div>
  )
}

export default App;
